<template>
    <div id="nt_wrapper">

        <Header/>

        <div id="nt_content">

            <!--shop banner-->
            <div class="kalles-section page_section_heading">
                <div class="page-head tc pr oh cat_bg_img page_head_">
                    <div class="parallax-inner nt_parallax_false lazyload nt_bg_lz pa t__0 l__0 r__0 b__0" data-bgset="/img/banner-008.jpeg"></div>
                    <div class="container pr z_100">
                        <h1 class="mb__5 cw">{{options.title}}</h1>
                        <p class="mg__0" v-html="options.description"></p>
                    </div>
                </div>
            </div>
            <!--end shop banner-->

            <div id="kalles-section-wishlist_page" class="container container_cat pop_default cat_default mb__20">

                <!--grid control-->
                <div class="cat_toolbar row fl_center al_center mt__30">
                    <div class="cat_view col-auto">
                        <div class="dn dev_desktop"><a href="#" data-mode="grid" data-dev="dk" data-col="6" class="pr mr__10 cat_view_page view_6"></a><a href="#" data-mode="grid" data-dev="dk" data-col="4" class="pr mr__10 cat_view_page view_4"></a><a href="#" data-mode="grid" data-dev="dk" data-col="3" class="pr mr__10 cat_view_page view_3 active"></a><a href="#" data-mode="grid" data-dev="dk" data-col="15" class="pr mr__10 cat_view_page view_15"></a><a href="#" data-mode="grid" data-dev="dk" data-col="2" class="pr cat_view_page view_2"></a></div>
                        <div class="dn dev_tablet dev_view_cat"><a href="#" data-dev="tb" data-col="6" class="pr mr__10 cat_view_page view_6"></a><a href="#" data-dev="tb" data-col="4" class="pr mr__10 cat_view_page view_4"></a><a href="#" data-dev="tb" data-col="3" class="pr cat_view_page view_3"></a></div>
                        <div class="flex dev_mobile dev_view_cat"><a href="#" data-dev="mb" data-col="12" class="pr mr__10 cat_view_page view_12"></a><a href="#" data-dev="mb" data-col="6" class="pr cat_view_page view_6"></a></div>
                    </div>
                </div>
                <!--end grid control-->

                <!--product section-->
                <div class="row">
                    <div class="col-lg-12 col-12">
                        <div class="kalles-section tp_se_cdt">

                            <!--filter result-->
                            <div class="result_clear mt__30 mb__20 dn">
                                <div class="sp_result_html dib cb clear_filter"><span class="cp">20</span> Products Found </div>
                                <a class="clear_filter dib" href="#">Clear All Filter</a><a href="#" class="clear_filter dib bf_icons" aria-label="Remove tag Size  M">Size M</a><a href="#" class="clear_filter dib bf_icons" aria-label="Remove tag Color  Cyan">Color Cyan</a><a href="#" class="clear_filter dib bf_icons" aria-label="Remove tag Vendor  Kalles">Vendor Kalles</a>
                            </div>
                            <!--end filter result-->

                            <!--products list-->
                            <div class="on_list_view_false prs_wis products nt_products_holder row fl_center row_pr_1 cdt_des_1 round_cd_false nt_cover ratio_nt position_8 space_30 nt_default">
                                <div v-for="(dt, index) in 10" :key="index" class="col-lg-3 col-md-3 col-6 pr_animated done mt__30 pr_grid_item product nt_pr desgin__1">
                                    <div class="product-inner pr">
                                        <div class="product-image position-relative oh lazyload"><span class="tc nt_labels pa pe_none cw"><span class="onsale nt_label"><span>-34%</span></span></span><a class="d-block" href="product-detail-layout-01.html">
                                            <div class="pr_lazy_img main-img nt_img_ratio nt_bg_lz lazyload padding-top__127_571" data-bgset="assets/images/megamenu/pr-11.jpg"></div>
                                        </a>
                                            <div class="hover_img pa pe_none t__0 l__0 r__0 b__0 op__0">
                                                <div class="pr_lazy_img back-img pa nt_bg_lz lazyload padding-top__127_571" data-bgset="assets/images/megamenu/pr-12.jpg"></div>
                                            </div>
                                            <div class="nt_add_w ts__03 pa "><a href="#" class="cb chp ttip_nt tooltip_right wis_remove"><span class="tt_txt">Remove Wishlist</span><i class="facl facl-heart-o"></i></a></div>
                                            <div class="hover_button op__0 tc pa flex column ts__03"><a class="pr nt_add_qv js_add_qv cd br__40 pl__25 pr__25 bgw tc dib ttip_nt tooltip_top_left" href="#"><span class="tt_txt">Quick view</span><i class="iccl iccl-eye"></i><span>Quick view</span></a><a href="#" class="pr pr_atc cd br__40 bgw tc dib js__qs cb chp ttip_nt tooltip_top_left"><span class="tt_txt">Quick Shop</span><i class="iccl iccl-cart"></i><span>Quick Shop</span></a></div>
                                        </div>
                                        <div class="product-info mt__15">
                                            <h3 class="product-title position-relative fs__14 mg__0 fwm"><a class="cd chp" href="product-detail-layout-01.html">La Bohème Rose Gold</a></h3>
                                            <span class="price dib mb__5"><del>$60.00</del><ins>$40.00</ins></span>
                                            <div class="swatch__list_js swatch__list lh__1 nt_swatches_on_grid"><span data-bgset="assets/images/products/pr-27.jpg" class="nt_swatch_on_bg swatch__list--item position-relative ttip_nt tooltip_top_right"><span class="tt_txt">Pink</span><span class="swatch__value bg_color_pink lazyload"></span></span><span data-bgset="assets/images/products/pr-35.jpg" class="nt_swatch_on_bg swatch__list--item position-relative ttip_nt tooltip_top_right"><span class="tt_txt">Black</span><span class="swatch__value bg_color_black lazyload"></span></span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end products list-->

                            <Pagination :count="options.search_data.count" :page="options.search_data.page" :page_size="options.search_data.page_size" :editPage="editPage" :editPageSize="editPageSize" />

                        </div>
                    </div>
                </div>
                <!--end product section-->

            </div>
        </div>

        <Footer/>

    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Pagination from '@/components/Pagination.vue'
import {getCurrentInstance, inject, onMounted, onUnmounted, reactive, ref} from "vue";
import '/public/css/drift-basic.min.css'
import '/public/css/photoswipe.css'
import '/public/css/blog.css'
import {xsListArticleV1Api} from "@/api/article/article";

export default {
    name: 'Like',
    components: {Header, Footer, Pagination},
    setup() {
        const {proxy} = getCurrentInstance();

        const website = inject('website');

        const options = reactive({
            title: '喜欢的产品',
            name: proxy.$route.name,
            url: proxy.$route.path,
            show_cat_open: true,
            search_data: {
                article_id: "",
                article_category_id: proxy.$route.params.id || '',
                title: proxy.$route.query.search_keyword || '',
                sort_by: [],
                recommends: [],
                tops: [],
                page: proxy.$route.query.page || 1,
                page_size: proxy.$route.query.page_size || 8,
                count: 0,
            },
        });

        const article_category = ref({
            title: '新闻中心'
        });

        const article_category_list = inject('article_category_list');
        if (proxy.$route.params.id) {
            article_category.value = article_category_list.value.find(o => o.article_category_id == proxy.$route.params.id);
        }

        const article_list = ref([]);
        const apiArticleList = () => {
            return new Promise(function (resolve) {
                xsListArticleV1Api(options.search_data).then(res => {
                    if (res.data.code == 0) {
                        article_list.value = res.data.data.list;
                        article_list.value.forEach(function(val) {
                            val.category = article_category_list.value.find(o => o.article_category_id == val.article_category_id);
                        });

                        options.search_data.count = res.data.data.count;
                    }

                    resolve(true);
                });
            });
        };

        const editPage = (page) => {
            options.search_data.page = page;

            apiArticleList();
        };
        const editPageSize = (page_size) => {
            options.search_data.page = 1;
            options.search_data.page_size = page_size;

            apiArticleList();
        };

        // 初始数据加载
        Promise.all([apiArticleList()]).then(() => {
            // apiarticleList().then(() => {
            //     mainNotyConfirm.close();
            // });
        });

        // watch(article_category_list, () => {
        //     article_category.value = article_category_list.value.find(o => o.article_category_id == proxy.$route.params.id);
        // });

        const loadScript = (url) => {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            document.getElementsByTagName("body")[0].appendChild(script);
        }
        onMounted(() => {
            document.getElementsByTagName('body')[0].className = 'kalles-template single-article-template zoom_tp_2 header_full_true des_header_3 css_scrollbar lazy_icons btnt4_style_2 template-index kalles_toolbar_true hover_img2 swatch_style_rounded swatch_list_size_small label_style_rounded wrapper_full_width hide_scrolld_true lazyload'

            setTimeout(function() {
                loadScript("/js/flickity.pkgd.min.js");
                loadScript("/js/interface.js");
            }, 500);
        })
        onUnmounted(() => {
            document.body.removeAttribute('class')
        })

        return {options, website, article_list, article_category, article_category_list, editPage, editPageSize}
    },
}
</script>

<style scoped>

</style>